<template>
	<div class="page">

		<!-- 头部操作区域 -->
		<div class="head_action">
			<div class="left_btns">
				<el-button type="primary" size="small" @click="download_excel">下载excel模板</el-button>
				<el-button type="primary" size="small" @click="load_excel">导入</el-button>
				<el-button type="danger" size="small" @click="page_clear">清空</el-button>
				<el-button type="success" size="small" @click="sub">提交</el-button>
				<el-button type="primary" size="small" @click="to_statistics('show')">点击统计</el-button>

				<!-- 数据统计 -->
				<div class="statistics">
					<div class="item">单子总量: {{statistics_rel.tord_total}} 单</div>
					<div class="item">运费总额: {{statistics_rel.freight_total}} 元</div>
					<div class="item">收款人总数: {{statistics_rel.truck_owner_total}} 个</div>
					<div class="item">司机总数: {{statistics_rel.driver_total}} 个</div>
					<div class="item">车辆总数: {{statistics_rel.truck_total}} 辆</div>
					<div class="item">货物总重: {{statistics_rel.cargo_weight_total}} 吨</div>
					<div class="item">货物总体积: {{statistics_rel.cargo_volume_total}} 方</div>
					<div class="item">导出第几页: {{tord_out.times}}</div>
				</div>
			</div>

			<div class="right_content">
				
			</div>
		</div>

		<!-- 表格 -->
		<div class="table">
			<div class="thead">
				<div class="tr">
					<div class="th" style="width: 11.4%;">收款人信息</div>
					<div class="th" style="width: 11.4%;">货车信息</div>
					<div class="th" style="width: 22.8%;">装货地数据/发车时间</div>
					<div class="th" style="width: 22.8%;">到货地数据/到货时间</div>
					<div class="th" style="width: 15.5%;">货物名称/运费/重量/体积</div>
					<div class="th" style="width: 15.5%;">组号/备注/操作</div>
				</div>
			</div>
			<div class="tbody">
				<div class="tr" v-for="(tord_item,index) in data" :key="index">
					<div class="td" style="width: 11.4%;">
						<div class="item">
							(收款人) {{tord_item.truck_owner_tel}} /
							{{user_list[tord_item.truck_owner_tel]?user_list[tord_item.truck_owner_tel].user_name:"无"}}
						</div>
						<div class="item">
							(司机) {{tord_item.driver_tel}} /
							{{user_list[tord_item.driver_tel]?user_list[tord_item.driver_tel].user_name:"无"}}
						</div>
					</div>
					<div class="td" style="width: 11.4%;">
						<div class="item">{{tord_item.truck_plate_num}}</div>
						<div class="item">
							{{truck_list[tord_item.truck_plate_num]?truck_list[tord_item.truck_plate_num].info_text:"无"}}
						</div>
					</div>
					<div class="td" style="width: 22.8%;">
						<div class="item">
							<div class="input">{{tord_item.case_info.prov}} / {{tord_item.case_info.city}} /
								{{tord_item.case_info.county}}</div>
						</div>
						<div class="item">
							<div class="input">{{tord_item.case_info.addr}}</div>
							<!-- <input class="input" v-model="tord_item.case_info.addr" placeholder="请输入装货地详细地址"> -->
						</div>
						<div class="item">
							<div class="input">{{tord_item.start_time.date}}</div>
						</div>
					</div>
					<div class="td" style="width: 22.8%;">
						<div class="item">
							<div class="input">{{tord_item.aim_info.prov}} / {{tord_item.aim_info.city}} /
								{{tord_item.aim_info.county}}</div>
						</div>
						<div class="item">
							<div class="input">{{tord_item.aim_info.addr}}</div>
							<!-- <input class="input" v-model="tord_item.aim_info.addr" placeholder="请输入装货地详细地址"> -->
						</div>
						<div class="item">
							<div class="input">{{tord_item.arrived_time.date}}</div>
						</div>
					</div>
					<div class="td" style="width: 15.5%;">
						<div class="item">{{tord_item.cargo_name}} / {{tord_item.freight_total}}元</div>
						<div class="item">{{tord_item.cargo_weight}}吨 / {{tord_item.cargo_volume}}方</div>
					</div>
					<div class="td" style="width: 15.5%;">
						<div class="item">
							<div class="input">{{tord_item.group1_num}}</div>
							<!-- <input class="input" v-model="tord_item.group1_num" placeholder="请输入组编号"> -->
						</div>
						<div class="item">
							<div class="input">{{tord_item.mark}}</div>
							<!-- <input class="input" v-model="tord_item.mark" placeholder="请输入备注"> -->
						</div>
						<div class="item">
							<div @click="tord_edit_open_view(index)" class="btn">修改</div>
							<div @click="tord_del(index)" class="btn red" style="margin-left: 5px;">删除</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- 读取excel弹出层 -->
		<el-dialog 
			title="读取进度" 
			width="600px" 
			:close-on-click-modal="false" 
			:close-on-press-escape="false"
			:show-close="false" 
			:visible.sync="load_excel_rate_of_progress.is_show"
		>
			<div v-if="load_excel_rate_of_progress.step==1" style="text-align: center;height: 80px;line-height: 80px;">读取中</div>
			<div v-if="load_excel_rate_of_progress.step==2" style="text-align: center;height: 80px;line-height: 80px;">
				{{load_excel_rate_of_progress.loaded}} / {{load_excel_rate_of_progress.total}}
			</div>
		</el-dialog>


		<!-- 上报弹出层 -->
		<el-dialog 
			title="上传进度" 
			width="600px" 
			:close-on-click-modal="false" 
			:close-on-press-escape="false"
			:show-close="false" 
			:visible.sync="send_rate_of_progress.is_show"
		>
			<div style="text-align: center;height: 80px;line-height: 80px;">
				{{send_rate_of_progress.sended}} / {{send_rate_of_progress.total}}
			</div>
		</el-dialog>


		<!-- 运单修改 -->
		<el-dialog title="运单修改" top="1vh" width="600px" :visible.sync="tord_edit_para.is_show">
			<el-form label-position="left" label-width="75px">
				<el-form-item label="收款人信息">
					<el-col :span="8">
						<el-input v-model="tord_edit_para.data.truck_owner_tel" clearable></el-input>
					</el-col>
					<el-col :span="16">
						<el-input v-if="user_list[tord_edit_para.data.truck_owner_tel]"
							v-model="user_list[tord_edit_para.data.truck_owner_tel].user_name" disabled>
							<el-button @click="user_ser(tord_edit_para.data.truck_owner_tel,'truck_owner_tel')"
								type="success" slot="append" style="width:80px">搜索</el-button>
						</el-input>
						<el-input v-else disabled>
							<el-button @click="user_ser(tord_edit_para.data.truck_owner_tel,'truck_owner_tel')"
								type="success" slot="append" style="width:80px">搜索</el-button>
						</el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="司机信息">
					<el-col :span="8">
						<el-input v-model="tord_edit_para.data.driver_tel" clearable></el-input>
					</el-col>
					<el-col :span="16">
						<el-input v-if="user_list[tord_edit_para.data.driver_tel]"
							v-model="user_list[tord_edit_para.data.driver_tel].user_name" disabled>
							<el-button @click="user_ser(tord_edit_para.data.driver_tel,'driver_tel')" type="success"
								slot="append" style="width:80px">搜索</el-button>
						</el-input>
						<el-input v-else disabled>
							<el-button @click="user_ser(tord_edit_para.data.driver_tel,'driver_tel')" type="success"
								slot="append" style="width:80px">搜索</el-button>
						</el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="货车信息">
					<el-col :span="8">
						<el-input v-model="tord_edit_para.data.truck_plate_num" clearable></el-input>
					</el-col>
					<el-col :span="16">
						<el-input v-if="truck_list[tord_edit_para.data.truck_plate_num]"
							v-model="truck_list[tord_edit_para.data.truck_plate_num].info_text" disabled>
							<el-button @click="truck_ser(tord_edit_para.data.truck_plate_num)" type="success"
								slot="append" style="width:80px">搜索</el-button>
						</el-input>
						<el-input v-else disabled>
							<el-button @click="truck_ser(tord_edit_para.data.truck_plate_num)" type="success"
								slot="append" style="width:80px">搜索</el-button>
						</el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="装货地 和 装货时间">
					<el-cascader v-model="tord_edit_para.data.case_info.codes" placeholder="装货地省市区" :options="cities"
						style="width: 100%;" filterable></el-cascader>
					<el-input v-model="tord_edit_para.data.case_info.addr" placeholder="装货地详细地址"
						style="margin-top: 5px;" clearable></el-input>
					<el-date-picker v-model="tord_edit_para.data.start_time.date_obj" type="datetime"
						style="width: 100%;margin-top: 5px;" filterable></el-date-picker>
				</el-form-item>
				<el-form-item label="到货地 和 到货时间">
					<el-cascader v-model="tord_edit_para.data.aim_info.codes" placeholder="装货地省市区" :options="cities"
						style="width: 100%;" filterable></el-cascader>
					<el-input v-model="tord_edit_para.data.aim_info.addr" placeholder="装货地详细地址" style="margin-top: 5px;"
						clearable></el-input>
					<el-date-picker v-model="tord_edit_para.data.arrived_time.date_obj" type="datetime"
						style="width: 100%;margin-top: 5px;" filterable></el-date-picker>
				</el-form-item>
				<el-form-item label="货物名称 重量/体积">
					<el-input v-model="tord_edit_para.data.cargo_name" clearable></el-input>
					<el-col :span="12" style="margin-top: 5px;">
						<el-input v-model="tord_edit_para.data.cargo_weight" type="number" clearable>
							<el-button slot="append" style="width:60px">吨</el-button>
						</el-input>
					</el-col>
					<el-col :span="12" style="margin-top: 5px;">
						<el-input v-model="tord_edit_para.data.cargo_volume" type="number" clearable>
							<el-button slot="append" style="width:60px">方</el-button>
						</el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="组号/备注/运费">
					<el-input v-model="tord_edit_para.data.group1_num" placeholder="请输入组号" clearable></el-input>
					<el-input v-model="tord_edit_para.data.mark" placeholder="请输入备注" style="margin-top: 5px;" clearable>
					</el-input>
					<el-input v-model="tord_edit_para.data.freight_total" placeholder="请输入运费" type="number"
						style="margin-top: 5px;" clearable>
						<el-button slot="append" style="width:60px">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" style="width:90px" @click="tord_edit_confirm">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import xlsx from 'xlsx'
	import {mapState} from 'vuex'
	import {
		regionData,
		CodeToText,
		TextToCode
	} from 'element-china-area-data'
	export default {
		data() {
			return {

				//城市数据
				cities: regionData,

				//表格数据
				data: [], //列表数据

				//相关用户列表
				user_list: {},

				//货车列表
				truck_list: {},

				//统计数据
				statistics_rel: {

					//运单总数
					tord_total: 0,

					//运费总额
					freight_total: 0,

					//收款人总数
					truck_owner_total: 0,

					//司机总数
					driver_total: 0,

					//车辆总数
					truck_total: 0,

					//货物总重
					cargo_weight_total: 0,

					//货物总体积
					cargo_volume_total: 0,
				},

				//加载excel进度显示
				load_excel_rate_of_progress: {
					is_show: false,
					step: 1, //步骤(1:读取excel,2:写入页面)
					total: 0,
					loaded: 0,
				},

				//上传进度显示
				send_rate_of_progress: {
					is_show: false,
					total: 0,
					sended: 0,
				},

				//运单修改
				tord_edit_para: {
					index: -1,
					is_show: false,
					data: {
						case_info: {},
						start_time: {},
						arrived_time: {},
						aim_info: {},
					},
				},


				//临时用
				tord_out:{
					list:[],
					p:1,
					times:1,
				},
			}
		},
		computed:{
			...mapState(["user_info"])
		},
		methods: {

			//运单删除
			tord_del(index) {
				this.$my.other.confirm({
					content: '点击确定删除此运单',
					confirm: () => {
						this.data.splice(index, 1);
					}
				})
			},

			//运单修改
			tord_edit_open_view(index) {
				this.tord_edit_para.index = index
				this.tord_edit_para.is_show = true;
				this.tord_edit_para.data = {
					...this.data[index]
				}
			},
			tord_edit_confirm() {

				//取出修改后的数据
				let new_tord_data = this.tord_edit_para.data

				//获取新的城市数据
				new_tord_data.case_info.prov = CodeToText[new_tord_data.case_info.codes[0]]
				new_tord_data.case_info.city = CodeToText[new_tord_data.case_info.codes[1]]
				new_tord_data.case_info.county = CodeToText[new_tord_data.case_info.codes[2]]

				new_tord_data.aim_info.prov = CodeToText[new_tord_data.aim_info.codes[0]]
				new_tord_data.aim_info.city = CodeToText[new_tord_data.aim_info.codes[1]]
				new_tord_data.aim_info.county = CodeToText[new_tord_data.aim_info.codes[2]]

				//获取新的发到货时间数据
				new_tord_data.start_time.date = this.$my.other.totime(new_tord_data.start_time.date_obj)
				new_tord_data.start_time.timestamp = (new_tord_data.start_time.date_obj.getTime() / 1000).toFixed(0)

				new_tord_data.arrived_time.date = this.$my.other.totime(new_tord_data.arrived_time.date_obj)
				new_tord_data.arrived_time.timestamp = (new_tord_data.arrived_time.date_obj.getTime() / 1000).toFixed(0)

				//回填
				this.data[this.tord_edit_para.index] = {
					...this.tord_edit_para.data
				}

				//关闭弹出层
				this.tord_edit_para.is_show = false;
			},


			//导入录单表格
			load_excel() {
				this.$my.file.choose({
					accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					success: (res) => {

						//进度框显示状态 开启&&第一步
						this.load_excel_rate_of_progress.is_show = true;
						this.load_excel_rate_of_progress.step = 1;

						//
						let file_reader = new FileReader();
						file_reader.onload = (ev) => {
							let workbook = xlsx.read(ev.target.result, {
								type: 'binary'
							})
							let tord_list = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[
								0]]) // 生成json表格内容
							this.tord_list_render(tord_list)
						}
						file_reader.readAsBinaryString(res.file)
					}
				})
			},
			tord_list_render(tord_list) { //把excel里读取到的数据渲染到页面内

				//进入第二步
				this.load_excel_rate_of_progress.step = 2;
				this.load_excel_rate_of_progress.loaded = 0;
				this.load_excel_rate_of_progress.total = tord_list.length;

				//声明写入页面函数
				var that = this;
				var add = function(start, end) {

					//取出数据
					let tords = [];
					for (var i = start; i <= end; i++) {
						if (tord_list[i]) {
							tords.push(tord_list[i]);
						} else break;
					}
					if (tords.length == 0) {
						return;
					}

					//开始写入页面
					let list = [];
					let user_list = {};
					let truck_list = {};
					for (let item of tords) {

						//汇集收款人信息
						user_list[item["承运人手机号"]] = {
							tel: item["承运人手机号"],
							status: 1, //状态(1:未读取,2:读取成功,3:读取成功但不可用,4:读取失败)
							user_num: '',
							user_name: '无'
						}

						//汇集司机信息
						user_list[item["承运司机手机号"]] = {
							tel: item["承运司机手机号"],
							status: 1, //状态(1:未读取,2:读取成功,3:读取成功但不可用,4:读取失败)
							user_num: '',
							user_name: '无'
						}

						//汇集货车信息
						truck_list[item["车牌号"]] = {
							truck_plate_num: item["车牌号"],
							status: 1, //状态(1:未读取,2:读取成功,3:读取成功但不可用,4:读取失败)
							info: {},
							info_text: '无', //truck_type_name+'/'+truck_type_name2+'/'+truck_weight
						}

						//装货地数据
						let case_prov = item["装货地省"].split('_')[0]
						let case_city = item["装货地市"].split('_')[0]
						let case_county = item["装货地区"].split('_')[0]

						//发车时间处理
						let start_time_timestamp = that.$my.other.excelDateToTimestamp(item["发车时间"])

						//到货地数据
						let aim_prov = item["到货地省"].split('_')[0]
						let aim_city = item["到货地市"].split('_')[0]
						let aim_county = item["到货地区"].split('_')[0]

						//到货时间处理
						let arrived_time_timestamp = that.$my.other.excelDateToTimestamp(item["到货时间"])

						//运单列表
						list.push({
							truck_owner_tel: item["承运人手机号"],
							driver_tel: item["承运司机手机号"],
							truck_plate_num: item["车牌号"],
							case_info: {
								prov: case_prov,
								city: case_city,
								county: case_county,
								codes: [
									TextToCode[case_prov].code,
									TextToCode[case_prov][case_city].code,
									TextToCode[case_prov][case_city][case_county].code,
								],
								addr: item["装货地详情(选填)"] ? item["装货地详情(选填)"] : '',
							},
							aim_info: {
								prov: aim_prov,
								city: aim_city,
								county: aim_county,
								codes: [
									TextToCode[aim_prov].code,
									TextToCode[aim_prov][aim_city].code,
									TextToCode[aim_prov][aim_city][aim_county].code,
								],
								addr: item["到货地详情(选填)"] ? item["到货地详情(选填)"] : '',
							},
							cargo_name: item["货物名称"],
							cargo_weight: item["重量(吨)"],
							cargo_volume: item["体积(方)"],
							freight_total: item["运费(元/趟)"],
							start_time: {
								timestamp: start_time_timestamp,
								date_obj: new Date(start_time_timestamp * 1000),
								date: that.$my.other.totime(start_time_timestamp),
							},
							arrived_time: {
								timestamp: arrived_time_timestamp,
								date_obj: new Date(arrived_time_timestamp * 1000),
								date: that.$my.other.totime(arrived_time_timestamp),
							},
							group1_num: item["组编号(选填)"] ? item["组编号(选填)"] : '',
							mark: item["备注(选填)"] ? item["备注(选填)"] : '',
						})
					}

					//置入页面
					that.user_list = Object.assign(user_list, that.user_list);
					that.truck_list = Object.assign(truck_list, that.truck_list);
					that.data = that.data.concat(list);

					//完成条数
					that.load_excel_rate_of_progress.loaded += tords.length

					//全部加载完成
					if (that.load_excel_rate_of_progress.loaded == that.load_excel_rate_of_progress.total) {

						//关闭进度显示器
						that.load_excel_rate_of_progress.is_show = false;

						//读取缺失信息的收款人和司机信息
						that.get_users_info()

						//读取缺失的货车信息
						that.get_trucks_info()

					} else {

						//加载下10条
						setTimeout(function() {
							add(start + 500, end + 500)
						}, 10)
					}
				}

				//发送 0-10 条
				add(0, 499)
			},


			//读取缺失的货车信息
			get_trucks_info() {

				//汇总需要查的车牌号
				let truck_plate_nums = [];
				for (var i in this.truck_list) {
					if (this.truck_list[i].status == 1) {
						truck_plate_nums.push(this.truck_list[i].truck_plate_num)
					}
				}

				//如果没有新的车牌号出现则不需要查询
				if (truck_plate_nums.length == 0) {
					return;
				}

				//调用接口查询数据
				this.$my.net.req({
					data: {
						mod: 'truck',
						ctr: 'truck_list_api',
						truck_plate_nums: truck_plate_nums,
						status: 2,
					},
					callback: (data) => {

						//报告结果
						this.$my.other.msg({
							type: 'success',
							str: '车辆信息查找完成'
						})

						//置入
						for (var truck of data.list) {
							this.truck_list[truck.truck_plate_num].status = 2
							this.truck_list[truck.truck_plate_num].info = {
								...truck
							}
							this.truck_list[truck.truck_plate_num].info_text = truck.truck_type_name + "/" +
								truck.truck_type_name2 + "/" + truck.truck_weight + "吨"
						}
					}
				})
			},


			//查找货车
			truck_ser(truck_plate_num) {

				//已经查找过
				if (this.truck_list[truck_plate_num]) {

					this.$my.other.msg({
						type: 'warning',
						str: '此车辆已经查找过,具体信息以表格内为准'
					})
					return;
				}

				//置入待查找组
				this.$set(this.truck_list, truck_plate_num, {
					truck_plate_num: truck_plate_num,
					status: 1, //状态(1:未读取,2:读取成功,3:读取成功但不可用,4:读取失败)
					info: {},
					info_text: '无', //truck_type_name+'/'+truck_type_name2+'/'+truck_weight
				})

				//调用查找函数
				this.get_trucks_info()
			},


			//读取缺失信息的收款人和司机信息
			get_users_info() {

				//汇总需要查的用户列表
				let user_tels = [];
				for (var i in this.user_list) {
					if (this.user_list[i].status == 1) {

						//不能是货主自己
						if (this.user_info.tel == this.user_list[i].tel) {
							this.$my.other.msg({
								type: 'warning',
								str: '收款人或者司机不能是货主自己'
							})
							return
						}

						user_tels.push(this.user_list[i].tel)
					}
				}

				//如果没有新的用户出现则不需要查询
				if (user_tels.length == 0) {
					return;
				}

				//调用接口查询数据
				this.$my.net.req({
					data: {
						mod: 'app_user',
						ctr: 'user_list_by_app_user',
						tels: user_tels,
						status: 1,
						real_status: 2,
					},
					callback: (data) => {

						//报告结果
						this.$my.other.msg({
							type: 'success',
							str: '收款人信息和司机信息查找完成'
						})

						//置入用户搜索结果
						for (var user of data.list) {
							this.user_list[user.tel].status = 2
							this.user_list[user.tel].user_num = user.user_num
							this.user_list[user.tel].user_name = user.name
						}
					}
				})
			},


			//搜索用户
			user_ser(tel, type) {

				//已经查找过
				if (this.user_list[tel]) {

					this.$my.other.msg({
						type: 'warning',
						str: '此用户已经查找过,具体信息以表格内为准'
					})
					return;
				}

				//置入待查找组
				this.$set(this.user_list, tel, {
					tel: tel,
					status: 1, //状态(1:未读取,2:读取成功,3:读取成功但不可用,4:读取失败)
					user_name: '无'
				})

				//调用查找函数
				this.get_users_info()
			},


			//统计
			to_statistics(type) {
				let freight_total = 0;
				let truck_owner_total = 0;
				let driver_total = 0;
				let truck_total = 0;
				let cargo_weight_total = 0;
				let cargo_volume_total = 0;
				let loaded_truck_owner_total = [];
				let loaded_driver_total = [];
				let loaded_truck_total = [];
				for (var loaded of this.data) {

					//总运费
					freight_total += parseFloat(loaded.freight_total)

					//统计收款人总数
					if (this.user_list[loaded.truck_owner_tel].status == 2 && loaded_truck_owner_total.indexOf(loaded
							.truck_owner_tel) == -1) {
						loaded_truck_owner_total.push(loaded.truck_owner_tel)
						truck_owner_total++
					}

					//统计司机总数
					if (this.user_list[loaded.driver_tel].status == 2 && loaded_driver_total.indexOf(loaded.driver_tel) ==
						-1) {
						loaded_driver_total.push(loaded.driver_tel)
						driver_total++
					}

					//统计货车总数
					if (this.truck_list[loaded.truck_plate_num].status == 2 && loaded_truck_total.indexOf(loaded
							.truck_plate_num) == -1) {
						loaded_truck_total.push(loaded.truck_plate_num)
						truck_total++
					}

					//已录入单子总重量
					cargo_weight_total += parseFloat(loaded.cargo_weight)

					//已录入单子总体积
					cargo_volume_total += parseFloat(loaded.cargo_volume)
				}

				//不同的操作
				if(type=='show'){
					this.statistics_rel.tord_total = this.data.length
					this.statistics_rel.freight_total = freight_total
					this.statistics_rel.truck_owner_total = truck_owner_total
					this.statistics_rel.driver_total = driver_total
					this.statistics_rel.truck_total = truck_total
					this.statistics_rel.cargo_weight_total = cargo_weight_total
					this.statistics_rel.cargo_volume_total = cargo_volume_total
				}else if(type=='return'){
					return {
						tord_total:this.data.length,
						freight_total:freight_total,
						truck_owner_total:truck_owner_total,
						driver_total:driver_total,
						truck_total:truck_total,
						cargo_weight_total:cargo_weight_total,
						cargo_volume_total:cargo_volume_total,
					}
				}
			},


			//数据发送控制器
			tord_send(truck_tord_event_num) {

				let tord_list=this.data

				//初始化进度显示器
				this.send_rate_of_progress.is_show = true;
				this.send_rate_of_progress.total = this.data.length;
				this.send_rate_of_progress.sended = 0;

				//声明提交函数
				var that = this;
				var sub = function(start, end) {

					//取出数据
					let tords = [];
					for (var i = start; i <= end; i++) {
						if (tord_list[i]) {
							tords.push(tord_list[i]);
						} else break;
					}
					if (tords.length == 0) {
						return;
					}

					//发送数据
					that.$my.net.req({
						data: {
							mod: 'truck_tord_after_time',
							ctr: 'tord_batch_add_by_shipper',
							tord_list: tords,
							truck_tord_event_num:truck_tord_event_num,
						},
						callback: (data) => {

							//完成条数
							that.send_rate_of_progress.sended += tords.length

							//上传完成
							if (that.send_rate_of_progress.sended == that.send_rate_of_progress.total) {
								that.send_rate_of_progress.is_show = false;
								
								//清空页面内的数据
								that.page_clear()
							} else {

								//上传下10条
								sub(start + 10, end + 10)
							}
						}
					})
				}

				//发送 0-10 条
				sub(0, 9)
			},

			//提交数据
			sub() {

				let data = this.data;
				if (data.length == 0) {
					this.$my.other.msg({
						type: 'warning',
						str: '已选择运单为空,请选择要提交的运单'
					})
					return;
				}

				//检查要上传的数据是否完整
				for (var i = 0; i < data.length; i++) {

					var tord = data[i];

					//收款人信息
					if (!this.$my.check.is_tel(tord.truck_owner_tel)) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的收款人手机号码有误"
						})
						return;
					}
					if (!this.user_list[tord.truck_owner_tel]) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的收款人没有校验,请点击对应行收款人信息的'查找'按钮"
						})
						return;
					}
					if (this.user_list[tord.truck_owner_tel].status != 2) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的收款人信息校验失败,请前往检查"
						})
						return;
					}
					tord.truck_owner_num = this.user_list[tord.truck_owner_tel].user_num;

					//司机信息
					if (!this.$my.check.is_tel(tord.driver_tel)) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的司机手机号码有误"
						})
						return;
					}
					if (!this.user_list[tord.driver_tel]) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的司机没有校验,请点击对应行司机信息的'查找'按钮"
						})
						return;
					}
					if (this.user_list[tord.driver_tel].status != 2) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的司机信息校验失败,请前往检查"
						})
						return;
					}
					tord.driver_num = this.user_list[tord.driver_tel].user_num;

					//货车信息
					if (!this.$my.check.is_plate_num(tord.truck_plate_num)) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的车牌号有误"
						})
						return;
					}
					if (!this.truck_list[tord.truck_plate_num]) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的货车没有校验,请点击对应行货车信息的'查找'按钮"
						})
						return;
					}
					if (this.truck_list[tord.truck_plate_num].status != 2) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的货车信息校验失败,请前往检查"
						})
						return;
					}

					//装货地数据和发车时间
					if (!(tord.case_info.prov && tord.case_info.city && tord.case_info.county)) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的装货地省市县不完整,请前往检查"
						})
						return;
					}

					//到货地数据和发车时间
					if (!(tord.aim_info.prov && tord.aim_info.city && tord.aim_info.county)) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的到货地省市县不完整,请前往检查"
						})
						return;
					}

					//检查发货地和到货地是否重复
					if (
						tord.case_info.prov == tord.aim_info.prov && 
						tord.case_info.city == tord.aim_info.city && 
						tord.case_info.county == tord.aim_info.county && 
						tord.case_info.addr == tord.aim_info.addr
					) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的发货地和到货地完全一样,请前往检查"
						})
						return;
					}

					//发货时间
					if (!this.$my.check.is_num(tord.start_time.timestamp)) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的发车时间有误,请前往检查"
						})
						return;
					}
					if (tord.start_time.timestamp <= 0) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的发车时间有误,请前往检查"
						})
						return;
					}

					//到货时间
					if (!this.$my.check.is_num(tord.arrived_time.timestamp)) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的到货时间有误,请前往检查"
						})
						return;
					}
					if (tord.arrived_time.timestamp <= 0) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的到货时间有误,请前往检查"
						})
						return;
					}

					//发到货时间逻辑校验
					if (tord.arrived_time.timestamp - tord.start_time.timestamp < 10800) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的发到货时间间隔不足3小时,请前往检查"
						})
						return;
					}

					//货物名称
					if (!tord.cargo_name.trim()) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单缺少货物名称,请前往检查"
						})
						return;
					} else tord.cargo_name = tord.cargo_name.trim();

					//货物重量
					tord.cargo_weight = parseFloat(tord.cargo_weight)
					if (tord.cargo_weight <= 0) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的货物重量小等于0,请前往检查"
						})
						return;
					}
					if (tord.cargo_weight > parseFloat(this.truck_list[tord.truck_plate_num].info.truck_weight)) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单超载,请前往检查"
						})
						return;
					}

					//运费
					tord.freight_total = parseFloat(tord.freight_total)
					if (tord.freight_total <= 0) {
						this.$my.other.msg({
							type: 'warning',
							str: '选中的第' + (i + 1) + "单的运费小等于0,请前往检查"
						})
						return;
					}
				}

				//创建提交事件
				this.creat_tord_check_event()
			},


			//创建提交事件
			creat_tord_check_event(){

				//统计
				let statistics_info=this.to_statistics('return')

				//添加提交事件
				this.$my.net.req({
					data: {
						mod: 'truck_tord_after_time',
						ctr: 'tord_batch_add_creat_add_event_by_shipper',
						tord_total_num:statistics_info.tord_total,
					},
					callback: (data) => {

						//发送数据
						this.tord_send(data.truck_tord_event_num)
					}
				})
			},


			//下载导入模板
			download_excel() {

				this.$my.net.download("static/上传订单模板.xlsx")
			},


			//清空页面
			page_clear() {

				this.$my.other.confirm({
					content: '点击确定,清空已导入数据',
					confirm: () => {
						this.data = [];
						this.selected = [];
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}

	.head_action {
		display: flex;
		justify-content: space-between;

		.left_btns {
			display: flex;
		}

		.statistics {
			display: flex;
			justify-content: flex-end;

			.item {
				height: 32px;
				line-height: 32px;
				font-size: 14px;
				margin-left: 10px;
				background-color: #666;
				color: #fff;
				padding: 0 10px;
			}
		}

		.right_content {
			display: flex;
			justify-content: flex-end;

			.item {
				line-height: 32px;
				font-size: 14px;
				margin-left: 20px;
			}
		}
	}

	.table {
		background-color: #fff;
		color: #666;
		text-align: left;
		height: calc(100% - 60px);
		font-size: 12px;
		border: 1px solid #ddd;
		border-width: 1px 0.5px 1px 1px;
		margin-top: 15px;

		.thead {
			.tr {
				display: flex;

				.th {
					border: 1px solid #ddd;
					border-width: 0 0.5px 1px 0;
					padding: 10px;
					background-color: #efefef;
				}
			}
		}

		.tbody {
			height: calc(100% - 41px);
			overflow-y: auto;

			.tr {
				display: flex;

				.td {
					border: 1px solid #ddd;
					border-width: 0 0.5px 1px 0;
					padding: 10px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;

					.item {
						display: flex;
					}

					.input {
						width: 100%;
						margin: 2px 0;
						display: block;
						height: 26px;
						border: 1px solid #ddd;
						padding-left: 10px;
						font-size: 12px;
						line-height: 26px;
					}

					.input:-webkit-input-placeholder {
						color: #ccc;
					}

					.input::-webkit-input-placeholder {
						color: #ccc;
					}

					.input:-moz-placeholder {
						color: #ccc;
					}

					.input::-moz-placeholder {
						color: #ccc;
					}

					.fenge {
						width: 10px;
					}

					.readonly {
						color: #999;
						background-color: #f5f7fa;
					}

					.btn {
						width: 70px;
						height: 28px;
						text-align: center;
						cursor: pointer;
						line-height: 28px;
						color: #fff;
						background-color: #409eff;
						font-size: 12px;
					}

					.red {
						background-color: #f56c6c;
					}
				}
			}
		}
	}
</style>
